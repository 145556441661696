<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: userProfile.vue
Description: This file contains UI components of user profile which includes all input fields requried to gather basic user information
-->
<template>
  <el-row
    class="user-profile"
    v-loading="pageLoading"
    element-loading-background="white"
  >
    <el-row
      v-if="!pageLoading"
      style="padding-left: 15px; padding-top: 12px; padding-bottom: 12px"
    >
      <p v-show="!pageLoading">{{ `${$t("Usrs_edit_profile")}` }}</p>
      <div v-show="!pageLoading">
        <div>
          <er-form
            ref="userDetailsForm"
            label-position="left"
            size="small"
            :model="user"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
              "
            >
              <div class="user-profile-photo">
                <el-image
                  fit="fill"
                  :src="getProfileUrl || eruvakaIcon"
                  :alt="$t('profile_picture')"
                ></el-image>
                <div class="upload-photo-buttons">
                  <er-button
                    icon="el-icon-camera"
                    size="mini"
                    v-show="$gblUAMIsHighPriorityUser"
                    :disabled="isReadOnly"
                    :title="$t('Comn_upload')"
                    circle
                    @click="handleUploadClick"
                  ></er-button>
                </div>
              </div>
              <div>
                <div class="user_details_container">
                  <er-form-item
                    :label="userFormLabels[0].label"
                    :error="
                      aekmm__castErrorKeyToLang(
                        ehm__errMessagesObject.first_name
                      )
                    "
                  >
                    <el-input
                      class="el-input--captalize"
                      :disabled="disabled"
                      v-model="user.first_name"
                      :placeholder="userFormLabels[0].placeholder"
                    ></el-input>
                  </er-form-item>
                </div>

                <div>
                  <er-form-item
                    :label="userFormLabels[3].label"
                    :error="
                      aekmm__castErrorKeyToLang(ehm__errMessagesObject.phone)
                    "
                  >
                    <el-input
                      :disabled="disabled"
                      v-model="user.phone"
                      :placeholder="userFormLabels[3].placeholder"
                    ></el-input>
                  </er-form-item>
                </div>
              </div>
              <div>
                <div class="user_details_container">
                  <er-form-item
                    :label="userFormLabels[1].label"
                    :error="
                      aekmm__castErrorKeyToLang(
                        ehm__errMessagesObject.last_name
                      )
                    "
                  >
                    <el-input
                      class="el-input--captalize"
                      :disabled="disabled"
                      v-model="user.last_name"
                      :placeholder="userFormLabels[1].placeholder"
                    ></el-input>
                  </er-form-item>
                </div>

                <div>
                  <er-form-item
                    :label="userFormLabels[4].label"
                    :error="
                      aekmm__castErrorKeyToLang(
                        ehm__errMessagesObject.country.name
                      )
                    "
                  >
                    <er-select
                      v-model="user.country"
                      value-key="code"
                      autocomplete="new-country"
                      :disabled="disabled"
                      filterable
                      :placeholder="userFormLabels[4].placeholder"
                      @change="handleCountryChange($event, 'SELECT_CHANGED')"
                    >
                      <er-option
                        v-for="(name, code) in countries"
                        :key="code"
                        :label="ftm__capitalize(name)"
                        :value="{ name, code }"
                      ></er-option>
                    </er-select>
                  </er-form-item>
                </div>
              </div>
              <div>
                <div class="user_details_container">
                  <er-form-item
                    :label="userFormLabels[2].label"
                    :error="
                      aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)
                    "
                  >
                    <el-input
                      :disabled="disabled"
                      v-model="user.email"
                      :placeholder="userFormLabels[2].placeholder"
                    ></el-input>
                  </er-form-item>
                </div>

                <div>
                  <er-form-item
                    :label="userFormLabels[5].label"
                    :error="
                      aekmm__castErrorKeyToLang(ehm__errMessagesObject.timezone)
                    "
                  >
                    <er-select
                      v-model="user.timezone"
                      autocomplete="new-timezone"
                      value-key="name"
                      :disabled="disabled"
                      filterable
                      :placeholder="userFormLabels[5].placeholder"
                      @change="handleChangeTimeZone"
                    >
                      <er-option
                        v-for="(timezone, name) in getTimeZones"
                        :key="name"
                        :label="ftm__capitalize(name)"
                        :value="timezone"
                      ></er-option>
                    </er-select>
                  </er-form-item>
                </div>
              </div>
            </div>
          </er-form>
        </div>
      </div>

      <layout-toolbar>
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :disabled="disabled || isReadOnly"
          @click="submitUpdatedDetails"
          :loading="profileLoading"
          v-show="!pageLoading"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="getUserProfile"
          :disabled="disabled || isReadOnly"
          :loading="pageLoading"
          v-show="!pageLoading"
        ></er-button>
      </layout-toolbar>

      <div>
        <el-divider v-show="!pageLoading"></el-divider>
      </div>
      <div>
        <updatePassword @password-change="handelPasswordChange" v-show="!pageLoading" :tabData="tabData"></updatePassword>
      </div>
    </el-row>
    <Loader v-else-if="pageLoading"> </Loader>
  </el-row>
</template>

<script>
import User from "@/model/user";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import updatePassword from "./updatePassword";
import { mapGetters, mapActions } from "vuex";
// import imageUpload from "./imageUpload";
import eruvakaIcon from "@/assets/eruvaka_icon.png";
import Loader from "@/components/base/Loader";
import filtersMixin from "@/mixins/filtersMixin.js";
import {
  erFormItem,
  erOption,
  erSelect,
  erForm
} from "@/components/base/basePondlogs";

export default {
  mixins: [
    errorHandlerMixin,
    authErrorKeyMapMixin,
    errorKeyMapMixin,
    filtersMixin
  ],
  components: {
    updatePassword,
    erFormItem,
    erForm,
    erOption,
    erSelect,
    // imageUpload,
    Loader
  },
  props: ['tabData'],
  data: function () {
    return {
      src: "",
      disabled: false,
      user: new User(),
      ehm__errMessagesObject: new User(),
      countries: {},
      profileLoading: false,
      loading: false,
      pageLoading: false,
      timezones: {},
      showUploadImageDialog: false,
      eruvakaIcon
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getProfileUrl: "getProfileUrl"
    }),
    getTimeZones() {
      const timezonesArr = Object.values(
        this.$store.getters["user/getTimeZones"]
      ).map((x) => ({
        name: x.name,
        offset: x.offset
      }));
      const timeZoneObj = {};
      timezonesArr.forEach((x) => {
        timeZoneObj[x.name] = x;
      });
      return timeZoneObj;
    },
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        {
          label: this.$t("Comn_email"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        {
          label: this.$t("Comn_mobile"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        {
          label: this.$t("Comn_country"),
          placeholder: this.$t("Usrs_enter_country")
        },
        {
          label: this.$t("Comn_timezone"),
          placeholder: this.$t("Usrs_select_timezone")
        }
      ];
    }
  },
  async mounted() {
    try {
      await this.getUserProfile();
      await this.$store.dispatch("auth/fetchCountryList");
      this.countries = this.$store.getters["auth/getCountries"];
    } catch (err) {
      this.ehm__errorMessages(err, true);
    }
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async getUserProfile() {
      this.ehm__errMessagesObject = new User();
      this.pageLoading = true;
      try {
        const userDetails = await this.$store.dispatch("user/fetchUserProfile");
        this.user = userDetails;
        await this.handleCountryChange(this.user.country, "INIT");
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.pageLoading = false;
      }
    },
    ehm__error409Handler(err, isAuthenticated) {
      const errorData = err.response.data;
      if (errorData.error_code === "EMAIL_ALREADY_EXISTS") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Email_already_registered"),
          type: "error"
        });
      }
    },
    async handleCountryChange(selectedCountry, action) {
      await this.$store.dispatch("user/fetchTimeZones", selectedCountry.code);
      if (action === "INIT" && this.user.timezone) {
        return;
      }
      this.user.timezone = this.getTimeZones[Object.keys(this.getTimeZones)[0]];
    },
    handleChangeTimeZone(timezone) {
      this.user.timezone = timezone;
    },
    handleUploadClick() {
      this.showUploadImageDialog = true;
    },
    async handleImageDialogClose(event) {
      this.showUploadImageDialog = false;
      if (event === "cancel") return;
      await this.getUserProfile();
    },
    async submitUpdatedDetails() {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.$gblUAMIsHighPriorityUsersRestricted();
        this.profileLoading = true;
        this.ehm__errMessagesObject = new User();
        this.user.first_name = this.trim_nameSpace(this.user.first_name);
        this.user.last_name = this.trim_nameSpace(this.user.last_name);
        await this.$store.dispatch("user/updateUserProfile", this.user);
        this.userErrorMsg = new User();
        this.$notify({
          title: this.$t("Usrs_edit_profile"),
          message: this.$t("Usrs_profile_upd_success"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({ eventName: "Settings - Edit Profile - Save" });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.profileLoading = false;
      }
    },
    handelPasswordChange() {
      this.mixPanelEventGenerator({ eventName: "Settings - Change Password - Save" });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/mixins";
.user-profile {
  .personal-data {
    .el-form-item__label {
      @include responsiveProperty(width, 92px, 105px, 128px);
    }
  }
  p {
    @include responsiveProperty(font-size, 1em, 0.9em, 0.85em);
    color: #3a9cff;
  }
  .el-form-item__error {
    padding-left: 1em;
  }
  .user-profile-header {
    width: 60%;
  }
  .user_profile_alignment {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // @include responsiveProperty(margin-top, -5px, -20px, -25px);
  }

  .user-profile-photo {
    position: relative;
    display: inline-grid;
    grid-template-rows: repeat(3, 25%);
    grid-template-columns: repeat(3, 25%);
    justify-content: center;
    align-content: center;
    .el-image {
      @include responsiveProperty(width, 100px, 125px, 130px);
      @include responsiveProperty(height, 100px, 125px, 130px);
      grid-row: 1 / 3;
      position: relative;
      grid-column: 1/3;
      clip-path: circle(50% at 50% 50%);
      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (1 / 1) * 100%;
      }
      > .el-image__inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .er-button {
      margin: 2px;
      border: unset;
      box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3),
        0 1px 3px 1px rgba(65, 69, 73, 0.15);
    }
  }
  .upload-photo-buttons {
    grid-row: 3 / 3;
    grid-column: 3/3;
    place-self: self-end;
    display: flex;
    align-items: center;
    justify-items: center;
    clip-path: circle(50% at 50% 50%);
    background: #fff;
  }
  .user_details_container {
    @include responsiveProperty(padding-bottom, 20px, 30px, 40px);
  }
  .el-input--small {
    @include responsiveProperty(font-size, 0.9em, 0.9em, 1em);
  }
  .er-form.er-form--user-profile {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .er-form {
    .grid-container {
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-gap: 10px;
      padding: 10px;
    }

    .grid-container > div:first-child {
      text-align: center;
      padding: 0px;
    }

    .grid-container > div {
      text-align: center;
      @include responsiveProperty(padding, 10px 0, 19px 0, 23px 0);
    }

    .item1 {
      grid-row: 1 / 4;
    }

    .er-form-item {
      .el-input__inner {
        border: none;
        border-bottom: 1px solid #dcdfe6;
        border-radius: unset;
        @include responsiveProperty(font-size, 0.9em, 1.1em, 1.3em);
        @include responsiveProperty(height, 2.1rem, 2.3em, 1.8rem);
        @include responsiveProperty(line-height, 2.1rem, 2.3em, 1.8rem);
      }
      .er-select {
        .el-input {
          display: inline-flex;
          flex-direction: row-reverse;
          .el-input__inner {
            padding-left: 15px;
            @include responsiveProperty(font-size, 0.9em, 1.1em, 1.3em);
            @include responsiveProperty(height, 2rem, 2rem, 1.8rem);
            @include responsiveProperty(line-height, 2rem, 2rem, 1.8rem);
          }
          .el-input__prefix {
            left: 0px;
            position: relative;
            width: 40px;
            text-align: center;
            padding: 0px 1.5px;
            @include responsiveProperty(height, 2rem, 2rem, 1.9rem);
            @include responsiveProperty(line-height, 2rem, 2rem, 2.1rem);
          }
        }
      }
      .el-form-item__label {
        color: #000000;
        @include small-text;
        font-weight: 700;
        @include responsiveProperty(inline-size, 64px, 74px, 89px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .el-form-item__content {
        display: flex;
        .el-input {
          color: #000000;
          @include responsiveProperty(min-width, 190px, 225px, 280px);
        }
        &:before {
          content: ":";
          margin-right: 14px;
        }
      }
    }
  }

  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin: 0px;
  }
}
</style>
